<template>
  <p :class="styleClass">{{ message }}</p>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    message: {
      type: String,
      required: true,
    },
    styleClass: {
      type: String,
      required: false,
    },
  },
};
</script>
